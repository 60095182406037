<template>
    <recess-card variant="variant1" class="px-5 my-3">
        <div class="row">
            <div class="col-12">
                <subtitle-text
                    :subtitle="PROVIDER.Portfolio.CourseEdit.CategoriesTab.Title"
                    qa-class="qa-edit-categories"
                />
                <static-alert
                    :message="PROVIDER.Portfolio.CourseEdit.CategoriesTab.EdudexCourseAlertMessage"
                    :is-visible="isEdudexCourse"
                />
            </div>
        </div>
                
        <div class="row">
            <div class="col-4 mb-4">
                <recess-multi-select
                    :selected-options="categoriesData.educationLevelCode"
                    :options="educationLevelCodes"
                    :search-input="true"
                    :single-select="true"
                    :label-text="PROVIDER.Portfolio.CourseEdit.CategoriesTab.EducationLevelCode.Label"
                    :placeholder="educationLevelCodes && educationLevelCodes[0].displayText"
                    class="qa-course-education-level-code"
                    :error-message="educationLevelCodeError"
                    @input="(newValue) => setEducationLevelCodeHandler(newValue)"
                />
            </div>

            <div class="col-12">
                <div 
                    v-for="(item, segmentIndex) in categoriesData.segments" 
                    :key="`segment-${segmentIndex}`"
                    class="row" 
                >
                    <div class="col-5">
                        <recess-multi-select
                            :selected-options="item.value"
                            :options="categoriesCourseData.segmentsOptions"
                            :search-input="true"
                            :single-select="true"
                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSegments.Label"
                            :placeholder="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSegments.Placeholder"
                            :error-message="segmentError(segmentIndex)"
                            :class="`qa-segment${segmentIndex}-segment`"
                            @input="(newValue) => onSegmentChange(segmentIndex, newValue)"
                        />
                    </div>

                    <div class="col-7 d-flex">
                        <recess-multi-select
                            v-model="item.subSegments"
                            :selected-options="item.subSegments"
                            :options="isOnCourseCreatePage ? item.filteredSubSegmentOptions : getFilteredSubSegmentOptions(item.value)"
                            :selected-value-as-object="!isOnCourseCreatePage"
                            :search-input="true"
                            :enable-preview-selected-items="true"
                            :label-text="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Label"
                            :placeholder="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Placeholder"
                            :error-message="subSegmentError(segmentIndex)"
                            :class="`w-100 mr-3 qa-segment${segmentIndex}-subsegment`"
                        />

                        <div class="d-flex align-items-center s-delete-icon__input-align">
                            <span 
                                v-if="categoriesData.segments !== null && categoriesData.segments.length > 0" 
                                :class="['c-icon c-icon__bin ', getRemoveSegmentClass(segmentIndex)]" 
                                @click="removeSegmentItem(segmentIndex)"
                            > </span>
                        </div>
                    </div>
                </div>
                <!-- add segment button -->
                <div class="row">
                    <div class="col-12 d-flex justify-content-end">
                        <button-component
                            type="button"
                            :back-label="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.AddSegmentsAndSubsegment"
                            icon-font="add"
                            button-class="m-0 c-button--variant4 qa-add-segment-item-button"
                            @click.native.prevent="addSegmentItem()"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <recess-divider show-line />
            </div>

            <div class="col-12 mt-3 d-flex justify-content-end">
                <recess-button
                    v-if="!isOnCourseEditPage"
                    variant="secondary"
                    :title="BUTTON_TEXT.previousTab"
                    class="qa-previous-tab-button mr-3"
                    @click.prevent.native="handleClickOnTab(null, 'previous-tab')"
                />

                <recess-button
                    variant="secondary"
                    :title="!isOnCourseEditPage ? BUTTON_TEXT.nextTab : BUTTON_TEXT.save"
                    class="qa-edit-course-submit-button"
                    @click.prevent.native="tabButtonAction()"
                />
            </div>
        </div>
    </recess-card>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { getEnumList } from '@/../../shared/api/SharedClient'

import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { isRequiredErrorMessage, requiredError, isUniqueErrorMessage, educationLevelSuccessAction, removeSegmentError } from '@/../../shared/constants/validationMessageHelper.js'
import tabMixin from '../../../mixins/tabNavigationMixin'

const SubtitleText = () => import('@/components/atoms/SubtitleText')
const StaticAlert = () => import('@/components/atoms/StaticAlert')
const ButtonComponent = () => import('@/components/atoms/ButtonComponent')

export default {
    name: 'EditCategories',
    components: {
        SubtitleText,
        StaticAlert,
        ButtonComponent
    },
    mixins: [validationMixin, tabMixin],
    inject: ['categoriesCourseData'],
	validations: {
        categoriesData: {
            educationLevelCode: {required},
		    segments: {
			    $each: {
				    value: {
					    required,
					    isUnique(_, segment) {
						    const mappedSegment = this.categoriesData.segments.map((e) => {
							    return e.value
						    })
						    const indexOfSegment = mappedSegment.indexOf(segment.value)
						    mappedSegment.splice(indexOfSegment, 1)
						    if (mappedSegment.length > 0) {
							    return mappedSegment.indexOf(segment.value) === -1
						    }
						    return true
					    }
				    },

				    subSegments: {
					    // eslint-disable-next-line func-names
					    required: requiredIf(function (segment) {
						    return this.subSegmentsRequired && this.hasSegmentValue(segment)
					    })
				    }
			    }
		    }
        }
	},
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            educationLevelCodes: [
                {
                    displayText:
                        PROVIDER.Portfolio.CourseEdit.CategoriesTab.EducationLevelCode.SelectOption,
                    value: ''
                }
            ],
            categoriesChanged: false,
            segmentsChanged: false,
            categoriesData: {}
        }
    },
    computed: {
        isEdudexCourse() {
            return this.categoriesData.source === 'Edudex'
        },
		subSegmentsRequired() {
			return this.categoriesData.source !== 'Edudex'
		},
		subSegmentLabel() {
			return this.subSegmentsRequired
				? `${PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Label}*`
				: PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Label
		},
        educationLevelCodeError() { 
            return isRequiredErrorMessage('educationLevelCode', 'Opleidingsniveau', this.$v.categoriesData.educationLevelCode)
        }
    }, 
    watch: {
        categoriesCourseData: {
            handler() {
                if(Object.entries(this.categoriesCourseData.categoriesData).length !== 0 && this.categoriesCourseData.categoriesData.constructor === Object) { 
                    this.categoriesData = JSON.parse(JSON.stringify(this.categoriesCourseData.categoriesData))

                    this.getEducationLevelCodes()
                }  
            },
            immediate: true,
            deep: true 
        },        
        categoriesData: {
            handler() {
                 this.$emit('triggerProgressChange', this.categoriesData)
            },
            deep: true
        }        
    },
    methods: {
        async getEducationLevelCodes() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'educationLevelCode',
                    null,
                    null,
                    false
                )
                if (!response) return
                this.educationLevelCodes = [...this.educationLevelCodes, ...response]
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving education level code enum list',
                    error
                )
            }
        },
		hasSegmentValue(segment) {
			return segment && segment.value
		},
        updateEditedCourse() {
            if (this.categoriesChanged || this.segmentsChanged) {
                this.categoriesChanged = false
                this.segmentsChanged = false

                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.$emit('submitForm')
                }

                const toastNotification = {
                    type: 'success',
                    message:
                        this.coursesIds && this.coursesIds > 1
                            ? educationLevelSuccessAction('bijgewerkt', true)
                            : educationLevelSuccessAction('bijgewerkt')
                }

                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })
            } else {
                this.$v.$touch()
                if (!this.$v.$invalid) {
                    this.$emit('submitForm')
                }
            }
        },
		getRemoveSegmentClass(index) {
			let defaulfClass = 'o-form__button'
			if (index > 0) {
				defaulfClass += ` mb-0 qa-remove-segments-button-${index}`
			} else {
				defaulfClass += ` qa-remove-segments-button-${index}`
			}

			return defaulfClass
		},
        setEducationLevelCodeHandler(value) {
            this.categoriesChanged = true
            this.categoriesData.educationLevelCode = value

            this.$v.categoriesData.educationLevelCode.$touch()
        },
        updatedSegments() {
            this.segmentsChanged = true
        },
		onSegmentChange(segmentIndex, segment) {
			const indexOfSegmentOption = this.categoriesCourseData.segmentsOptions.findIndex((x) => x.value === segment)
			const selectedOption = this.categoriesCourseData.segmentsOptions[indexOfSegmentOption]
			this.categoriesData.segments[segmentIndex].value = selectedOption.value
			this.categoriesData.segments[segmentIndex].id = selectedOption.id
			this.categoriesData.segments[segmentIndex].filteredSubSegmentOptions = selectedOption.filteredSubSegmentOptions
			this.categoriesData.segments[segmentIndex].subSegments = []
			this.$v.categoriesData.segments.$each[segmentIndex].value.$touch()
		},
		segmentError(index) {
			if (!this.$v.categoriesData.segments.$each[index].value.$error) return null

            return (
                isRequiredErrorMessage('segments', 'Segment', this.$v.categoriesData.segments.$each[index].value) ||
                isUniqueErrorMessage('Segment', this.$v.categoriesData.segments.$each[index].value, 'isUnique')
            )
		},
		subSegmentError(index) {
			return this.$v.categoriesData.segments.$each[index].subSegments.$invalid && !this.$v.categoriesData.segments.$each[index].subSegments.required ? requiredError('Subsegments') : null
		},
		removeSegmentItem(indexToRemove) {
			if (this.categoriesData.segments && this.categoriesData.segments.length === 1) {
				const toastNotification = {
					type: 'error',
					message: removeSegmentError
				}
				this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})
			}
			if (this.categoriesData.segments && this.categoriesData.segments.length > 1) {
				this.categoriesData.segments.splice(indexToRemove, 1)
			}
		},
		addSegmentItem() {
			const emptySegmentItem = {
				value: null,
				displayText: null,
				subSegments: [],
				filteredSubSegmentOptions: []
			}
            this.$v.$touch()
			this.categoriesData.segments.push(emptySegmentItem)
		},
        getFilteredSubSegmentOptions(selectedSegment) {
            const segmentIndex = this.categoriesCourseData.segmentsOptions.findIndex(option => option.value === selectedSegment)
            if (segmentIndex !== -1) {
                return this.categoriesCourseData.segmentsOptions[segmentIndex].filteredSubSegmentOptions
            } 
            return []
        }
    }
}
</script>
